.header{
   width: 100%;
    height:auto;
    display:flex;
    -webkit-box-shadow: 1px -6px 4px 4px #000000; 
    box-shadow: 1px -6px 4px 4px #000000;
    font-family: Helvetica;
}
.header .headerLeft{
    flex: 50%;
    display: flex;
    align-items: center;
    justify-content: left;
    margin-left: 20px;
}
.header .headerRight{
    flex: 50%;
    display: flex;
    align-items: center;
    justify-content: right;
    margin-right: 20px;

    /* background-color: green; */
}
.curveContainer{
    display: flex;
    border-style: solid;
    border-color: #347c80;
    border-width: 1px;
    height: 25px;
    align-items: center;
    border-radius: 15px;
    padding-right:7px;
    padding-left: 7px;
    color: #464444;
    font-size: 12px;
    margin-right:10px;
   
}
.header .imgLogo{
    width: 50px;
    height: auto;
    background-size: cover;
}
.header .notification{
   font-size:22px !important;
   color:#347c80;
   margin-right:10px;

}
.header .initialName{
    width:22px;
    height:22px;
    border-radius:11px;
    background-color: #67bcc0;
    display:flex;
    align-items:center;
    justify-content: center;
    color:#fff
}
@media only screen and (max-width:600px){
    .header .imgLogo{
        width: 90px;
        height: 30px;
    }
    .header .initialName{
        width:22px;
        height:22px;
        border-radius:11px;
        background-color: #67bcc0;
        display:flex;
        align-items:center;
        justify-content: center;
        color:#fff
    }
    .curveContainer{
        display: none;
        border-style: solid;
        border-color: #347c80;
        border-width: 1px;
        height: 25px;
        align-items: center;
        border-radius: 15px;
        padding-right:7px;
        padding-left: 7px;
        color: #464444;
        font-size: 12px;
        margin-right:10px;
       
    }
    .header .notification{
        font-size:18px !important;
        color:#347c80;
        margin-right:10px;
     
     }
}