.system-container{

    position: relative;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-gap: 16px;
    margin: 20px auto;

}


.text-center{
    text-align: center!important;
}