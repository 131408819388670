   .main-color{
      color: #3BA9AA!important
   }
   .sec-color{
      color: #3D4143
   }
   .main-bgcolor{
      background-color: #3BA9AA
   }
   .sec-bgcolor{
      background-color: #3D4143
   }

   .main-color-bold{
      color: #3BA9AA;
      font-weight: 900!important;
   }
   .txtfield-shadow{
      box-shadow: -4px 4px 5px 0px rgba(0,0,0,0.34) inset;
      -webkit-box-shadow: -4px 4px 5px 0px rgba(0,0,0,0.34) inset;
      -moz-box-shadow: -4px 4px 5px 0px rgba(0,0,0,0.34) inset;
      border-color: none;
      border-style: none;
      width: 100%;
      background-color: #3ba8aa11;
   }

   .login-flex-row-end{
   display: flex;
   justify-content: end;
   flex-direction: row;
   width: 50%;
   }
   .main-btcolor{
      background-color: #3BA9AA!important;
      color: white
   }

   .white-color{
      color: white!important
   }
   .cover-login{
      position: absolute;
      width: 40vw;
      height: auto;
      left: 1;
      right: 0;
      top: 0;
      background-image: url('../../assets/images/bg-sign-left.jpg');
      background-repeat: no-repeat;
      background-size:cover; 
   }

   .pos-relative{
      position: relative;
   }

   .max-height{
      height: 100vh;
   }
   .cover-conbtn{
      position: absolute!important;
      right: -50px!important;
      top: 50%;
   }